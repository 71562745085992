import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SupportedMarket } from '../types/market.types';

export type VisibilityValidationConfig = {
	visiblePrivate: SupportedMarket[];
	visibleCompany: SupportedMarket[];
	validatePrivate: SupportedMarket[];
	validateCompany: SupportedMarket[];
};

type AdditionalContactConfig = {
	firstName: VisibilityValidationConfig;
	lastName: VisibilityValidationConfig;
	email: VisibilityValidationConfig;
	phone: VisibilityValidationConfig;
};

type InvoiceAddressConfig = {
	street: VisibilityValidationConfig;
	city: VisibilityValidationConfig;
	postalCode: VisibilityValidationConfig;
	country: VisibilityValidationConfig;
};

type Config = {
	Configuration: {
		accountPosition: VisibilityValidationConfig;
		accountSocialSecurityNumber: VisibilityValidationConfig;
		companyName: VisibilityValidationConfig;
		companyNumber: VisibilityValidationConfig;
		vatNumber: VisibilityValidationConfig;
		naceCodeDescription: VisibilityValidationConfig;
		naceCodeNumber: VisibilityValidationConfig;
		contactPhone: VisibilityValidationConfig;
		contactCountry: VisibilityValidationConfig;
		additionalContact: AdditionalContactConfig;
		eInvoiceAddress: VisibilityValidationConfig;
		invoiceOperatorId: VisibilityValidationConfig;
		invoiceEmail: VisibilityValidationConfig;
		invoiceAddresses: InvoiceAddressConfig;
		atLeastOneInvoiceAddress: SupportedMarket[];
	};
};

@Injectable()
export class RegistrationService {
	constructor(private httpClient: HttpClient) {}

	public config: Config = {
		Configuration: {
			accountPosition: {
				visiblePrivate: [],
				visibleCompany: ['FI', 'SE', 'NO'],
				validatePrivate: [],
				validateCompany: ['NO'],
			},
			accountSocialSecurityNumber: {
				visiblePrivate: ['FI', 'SE', 'NO', 'EE'],
				visibleCompany: ['FI', 'SE', 'EE'],
				validatePrivate: ['FI', 'SE', 'NO', 'EE'],
				validateCompany: ['FI', 'SE', 'EE'],
			},
			companyName: {
				visiblePrivate: [],
				visibleCompany: ['FI', 'SE', 'EE'],
				validatePrivate: [],
				validateCompany: ['FI', 'SE', 'EE'],
			},
			companyNumber: {
				visiblePrivate: [],
				visibleCompany: ['FI', 'SE', 'EE'],
				validatePrivate: [],
				validateCompany: ['FI', 'SE'],
			},
			vatNumber: {
				visiblePrivate: [],
				visibleCompany: ['NO', 'EE'],
				validatePrivate: [],
				validateCompany: ['NO'],
			},
			naceCodeDescription: {
				visiblePrivate: [],
				visibleCompany: [],
				validatePrivate: [],
				validateCompany: [],
			},
			naceCodeNumber: {
				visiblePrivate: [],
				visibleCompany: [],
				validatePrivate: [],
				validateCompany: [],
			},
			contactPhone: {
				visiblePrivate: ['FI', 'SE', 'NO', 'EE'],
				visibleCompany: ['FI', 'SE', 'NO', 'EE'],
				validatePrivate: ['FI', 'SE', 'EE'],
				validateCompany: ['FI', 'SE', 'EE'],
			},
			contactCountry: {
				visiblePrivate: ['FI', 'SE', 'NO', 'EE'],
				visibleCompany: ['FI', 'SE', 'NO', 'EE'],
				validatePrivate: ['SE', 'NO'],
				validateCompany: ['SE', 'NO'],
			},
			additionalContact: {
				firstName: {
					visiblePrivate: ['FI', 'SE', 'NO', 'EE'],
					visibleCompany: ['FI', 'SE', 'NO', 'EE'],
					validatePrivate: ['SE'],
					validateCompany: ['SE'],
				},
				lastName: {
					visiblePrivate: ['FI', 'SE', 'NO', 'EE'],
					visibleCompany: ['FI', 'SE', 'NO', 'EE'],
					validatePrivate: ['SE'],
					validateCompany: ['SE'],
				},
				email: {
					visiblePrivate: ['SE', 'NO', 'EE'],
					visibleCompany: ['FI', 'SE', 'NO', 'EE'],
					validatePrivate: ['SE'],
					validateCompany: ['SE'],
				},
				phone: {
					visiblePrivate: ['FI', 'SE', 'NO', 'EE'],
					visibleCompany: ['FI', 'SE', 'NO', 'EE'],
					validatePrivate: ['SE'],
					validateCompany: ['SE'],
				},
			},
			eInvoiceAddress: {
				visiblePrivate: ['SE', 'NO'],
				visibleCompany: ['FI', 'SE', 'NO', 'EE'],
				validatePrivate: ['SE'],
				validateCompany: ['SE'],
			},
			invoiceOperatorId: {
				visiblePrivate: ['SE'],
				visibleCompany: ['FI', 'SE'],
				validatePrivate: ['SE'],
				validateCompany: ['SE'],
			},
			invoiceEmail: {
				visiblePrivate: ['FI', 'SE', 'NO'],
				visibleCompany: ['FI', 'SE', 'NO', 'EE'],
				validatePrivate: ['SE', 'NO'],
				validateCompany: ['SE', 'NO'],
			},
			invoiceAddresses: {
				street: {
					visiblePrivate: ['FI', 'SE', 'NO'],
					visibleCompany: ['FI', 'SE', 'NO', 'EE'],
					validatePrivate: ['FI', 'SE', 'NO'],
					validateCompany: ['FI', 'SE', 'NO'],
				},
				city: {
					visiblePrivate: ['FI', 'SE', 'NO'],
					visibleCompany: ['FI', 'SE', 'NO', 'EE'],
					validatePrivate: ['FI', 'SE', 'NO'],
					validateCompany: ['FI', 'SE', 'NO'],
				},
				postalCode: {
					visiblePrivate: ['FI', 'SE', 'NO'],
					visibleCompany: ['FI', 'SE', 'NO', 'EE'],
					validatePrivate: ['FI', 'SE', 'NO'],
					validateCompany: ['FI', 'SE', 'NO'],
				},
				country: {
					visiblePrivate: ['FI', 'SE', 'NO'],
					visibleCompany: ['FI', 'SE', 'NO', 'EE'],
					validatePrivate: ['SE', 'NO'],
					validateCompany: ['SE', 'NO'],
				},
			},
			atLeastOneInvoiceAddress: ['FI'],
		},
	};

	public register(registrationForm: unknown) {
		return this.httpClient.post('/webapi/register', registrationForm);
	}

	public getSignLink(registrationForm: unknown, market: string, language: string) {
		const encodedUrl = encodeURIComponent(`${window.location.origin}/${language}/registration/true`);

		const getRegistrationLinkRequestBody = {
			encodedBackLink: encodedUrl,
			newUser: registrationForm,
		};

		const url = `/webapi/${market}/${language}/digital-signing/new-user`;

		return this.httpClient.post(url, getRegistrationLinkRequestBody);
	}

	public getDocumentSignStatus(envelopeId: string, token: string) {
		const getDocumentSignStatus = {
			envelopeId: envelopeId,
			token: token,
		};

		const url = `/webapi/digital-signing/status`;
		return this.httpClient.post(url, getDocumentSignStatus);
	}

	public checkUser(userEmail: string) {
		return this.httpClient.post(`/webapi/register/check`, {
			Email: userEmail,
		});
	}
}

export enum RegistrationStatusCode {
	LOGIN_ALREADY_EXISTS = 'LOGIN_ALREADY_EXISTS',
	VALIDATION_ERROR = 'VALIDATION_ERROR',
	BAD_REQUEST = 'BAD_REQUEST',
	NO_MAIL_SENT_TO_BO = 'NO_MAIL_SENT_TO_BO',
	ERROR = 'ERROR',
}
